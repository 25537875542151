import React from 'react'
import { Link } from 'gatsby'
import { FaChevronLeft } from 'react-icons/fa'; 
import { IconContext } from "react-icons"; 
import { BrowserView } from "react-device-detect";


function BackButton() {
  return (

    <BrowserView>
      <div className="back-button">
          <Link to="/">
            <div className="back-button__text">
              <IconContext.Provider value={{ style: { verticalAlign: 'text-top' } }}>
                  <FaChevronLeft  size={18}/> Voltar para página inicial
              </IconContext.Provider>
            </div>
          </Link>
        </div>
    </BrowserView>
  )
}

export default BackButton
