import React from 'react';
import Carousel, { Modal, ModalGateway  } from 'react-images';
import DefaultImage from '../images/defaultImage.jpg'
import PropTypes from "prop-types";

class ImageModal extends React.Component {
  state = { modalIsOpen: false };

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };

  returnImage(imageSrc) {
    if (imageSrc) {
      return imageSrc;
    }
    return DefaultImage;
  };


  render() {
    const { modalIsOpen } = this.state;
    const imageSrc = this.returnImage(this.props.imageSrc);
    const images = [{ src: imageSrc }]

    return (
        <>
          <img alt="imagem do item" className="imagem" src={imageSrc} onClick={this.toggleModal}/>          

          <ModalGateway>
            {modalIsOpen ? (
              <Modal onClose={this.toggleModal}>
                <Carousel views={images} />
              </Modal>
            ) : null}
          </ModalGateway>

        </>
    );
  }
}

export default ImageModal;

ImageModal.propTypes = {
  returnImage: PropTypes.func.isRequired,
  imageSrc: PropTypes.string,
};