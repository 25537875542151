import React from 'react';
import Layout from '../components/layout';
import ImageModal from '../components/imageModal'
import Metatags from '../components/Metatags';
import Icon from '../images/gatsby-icon.png'
import { graphql } from 'gatsby'
import {Animated} from "react-animated-css";
import BackButoton from '../components/backButton'

function CategoryItem (props) {

  const item = props.item;
  const cardapiododia = props.cardapiododia;
  if (item) {
    if (cardapiododia) {
      return (
        <li className="li-cardapioDoDia">
          <p className="name-cardapiododia">- {item.itemName}</p>
        </li>
      )
      
    } else {
      return (
        <div className="li-cardapio">
          <li className="card">            
              <div className="card-left">
                <p className="name">{item.itemName}</p>
                <p className="description">{item.itemDescription}</p>
                <p className="price">{item.itemPrice}</p>
              </div>

              <div className="card-rigth">
                <ImageModal imageSrc={item.itemImageSrc}/>
              </div>
          </li>
        </div>
      );
    }
    
  }
}

function Subcategory (props) {

  const subcategory = props.subcategory;
  const showSubcategory = props.showSubcategory;
  
  return (
    <>
      {showSubcategory && subcategory.items != null &&
        <h2> {subcategory.subcategoryName}</h2>
      }

      {subcategory.items != null && subcategory.items.map((item, key) =>
          <CategoryItem item={item} key={item.name} cardapiododia={props.cardapiododia}/>
      )}
    </>
  )
}

function CategoryPost(props) {

    const post = props.data.markdownRemark;
    const url = props.data.site.siteMetadata.siteUrl
    const { name, subcategory, cardapiododia } = post.frontmatter;
    const thumbnail =
        post.frontmatter.image &&
        post.frontmatter.image.childImageSharp.resize.src;

    return (
        <Layout>
            <Metatags
                title={name}
                thumbnail={thumbnail ? url + thumbnail : url + Icon}
                url={url}
                pathname={props.location.pathname}
            />
            <BackButoton />
            <div className="l-main">
                <div className="l-main__row">
                  <div className="l-main__col">
                    <Animated animationIn="bounceInLeft" animationInDuration={700} isVisible={true}>
                      {thumbnail && 
                        <div 
                          className="l-main__photo"
                          style={{
                            backgroundImage: `url(${post.frontmatter.image.childImageSharp.fluid.src})`
                          }}
                        >
                          <div className="l-main__title">
                              {name}
                          </div>
                        </div>
                      }
                    </Animated>
                  </div>
                  <div className="l-main__col">
                    <Animated animationIn="bounceInLeft" animationInDuration={700} isVisible={true}>
                      <ul>                        
                        {subcategory && subcategory.map((item, key) =>
                            <Subcategory subcategory={item} cardapiododia={cardapiododia} showSubcategory={subcategory.length > 1} key={item.name} />
                        )}
                      </ul>
                    </Animated>
                  </div>                    
                </div>
                {/* <Animated animationIn="bounceInLeft" animationInDuration={700} isVisible={true}>testt</Animated> */}
            </div>
        </Layout>
    )
}


export default CategoryPost


export const query = graphql`

 query PostQuery($slug: String!) {
     markdownRemark(fields: { slug: { eq: $slug } }) {
       html
       frontmatter {
        name
        cardapiododia
        subcategory {
          subcategoryName
          items {
            itemName
            itemDescription          
            itemPrice
            itemImageSrc 
          }   
        }
        image {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
       }
       }
   }

  site {
    siteMetadata {
        siteUrl
      }
   }
}
`